<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.buildings.create.title' | translate }}
    v-card-content
      v-text-field.mb-3(
        :label="$t('base.name')"
        v-model="form.name")
      v-text-field(
        :label="$t('base.desc')"
        v-model="form.info")
    v-card-actions
      v-btn(
        :disabled="!isNameValid"
        :loading="loading"
        @click="onCreate") {{ 'actions.create' | translate }}
</template>

<script>
export default {
  name: 'CreateNewModal',

  data: () => ({
    form: {
      name: '',
      info: ''
    },
    loading: false
  }),

  computed: {
    isNameValid () {
      return !!this.form.name
    }
  },

  methods: {
    async onCreate () {
      this.loading = true
      const payload = {
        name: this.form.name,
        comment: this.form.info
      }
      const { data, error } = await this.$store.dispatch('addHousing', payload)
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(msg, {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
